.title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
  line-height: 0.7rem;
  font-family: "Poppins", sans-serif;
}

p {
  margin: 0;
}

.item {
  margin-top: 1rem;
}

.item a {
  background-color: #ffde59;
  border: none;
  padding: 5px 10px;
  border-radius: 6px;
  color: #292929;
  text-align: center;
  margin-left: 0;
}

.item a:hover {
  color: black;
}

.popupContent {
  font-size: 0.9rem;
}

.popupContent {
  background-color: #292929;
  color: #f0f0f0;
}

.mapboxgl-popup-content {
  background-color: #292929;
}