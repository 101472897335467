.filterWrapper {
  height: auto;
  width: 300px;
  background-color: #292929;
  color: #f0f0f0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}

.filterCollapsed {
  height: auto;
  width: 40px;
  background-color: #292929;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.collapsedButton {
  position: absolute;
  left: 40px;
  z-index: 1000;
  font-size: 20px;
  color: #292929;
  cursor: pointer;
  background-color: #969696;
  padding: 7px 7px;
}

.collapseButton {
  position: absolute;
  left: 300px;
  z-index: 1000;
  font-size: 20px;
  color: #292929;
  cursor: pointer;
  background-color: #969696;
  padding: 7px 7px;
}

@media (min-width: 720px) {
  .filterWrapper {
    height: auto;
    width: 400px;
    background-color: #292929;
    color: #f0f0f0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
  }

  .filterCollapsed {
    height: auto;
    width: 40px;
    background-color: #292929;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .collapsedButton {
    position: absolute;
    left: 40px;
    z-index: 1000;
    font-size: 20px;
    color: #292929;
    cursor: pointer;
    background-color: #969696;
    padding: 7px 7px;
  }

  .collapseButton {
    position: absolute;
    left: 400px;
    z-index: 1000;
    font-size: 20px;
    color: #292929;
    cursor: pointer;
    background-color: #969696;
    padding: 7px 7px;
  }
}

.filterHeader {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}

.logo {
  max-width: 150px;
}

.filterContent {
  flex: 15;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 0 15px 10px 15px;
}

.filterFooter {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  justify-content: flex-end;
  align-items: center;
}

.filterFooter img {
  height: 130px;
}

.footerLinks {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

a {
  color: #d6c686;
  margin: 0 10px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
a:hover {
  color: #ffde59;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.filterButton {
  margin: 0 auto 10px auto;
  padding: 5px 15px;
  background-color: #ffde59;
  color: black;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  line-height: 1.2rem;
  transition: all 0.25s;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.filterButton:hover {
  color: black;
  background-color: #a8933c;
  transition: all 0.25s;
}

h4 {
  margin-bottom: 2px;
  margin-top: 15px;
  color: #ffde59;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}

#orgFilter {
  margin-top: 0;
  margin-bottom: 5px;
}
#regionFilter {
  margin-top: 15px;
  margin-bottom: 5px;
}

.filterTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.filterTitleName {
  display: flex;
  align-items: center;
}
.filterTitleName h3 {
  margin: 0;
  margin-left: 7px;
}

.yearSelector {
  margin-top: 25px;
  padding: 0 2rem;
  margin-bottom: 30px;
}

.citySelect {
  display: flex;
  flex-direction: row;
}

.stateDiv {
  width: 25%;
  padding-right: 30px;
}

.cityDiv {
  width: 100%;
}

select option {
  background: black;
  color: #f0f0f0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
