.App {
  width: 100%;
  height: 100vh;
  font-family: "Roboto";
}

.text-uppercase {
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

.inputCustom {
  width: 100%;
  border: solid 1px #ffde59b7;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  padding: 0.4rem 0.5rem;
  background-color: transparent;
  color: #ffde59;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
