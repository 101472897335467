#partners-carousel {
  background-color: #fff;
  width: 100%;
  justify-content: center;
  display: flex;
}

#partners-carousel a {
  margin: 0;
}

.carousel-img {
  max-width: 100%;
}
