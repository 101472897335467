.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 2rem;
  text-align: center;
}

p {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;
}

button {
  border: solid 1px #292929;
  background-color: white;
  color: #292929;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  transition: all 0.25s;
}

button:hover {
  background-color: #292929;
  color: white;
}

@media(min-width: 720px) {
  .modal-main {
    width: 50%;
  }
}